<template>
  <div>
    <edit-icon-button v-if="isUpdate" @click="dialog = true" />
    <add-button v-else @click="dialog = true">Add Condition</add-button>
    <n-modal
      v-model:show="dialog"
      preset="card"
      :style="bodyStyle"
      :bordered="false"
      :closeOnEsc="false"
      :block-scroll="false"
      :maskClosable="false"
      :closable="false"
    >
      <form-wrapper @submit="submit()">
        <div class="title mb-3">If</div>
        <n-grid
          :x-gap="12"
          :cols="3"
          v-for="(item, i) in formCondition.conditions"
          :key="item.ts"
        >
          <n-gi cols="3">
            <select-field
              label="Field"
              filled
              filterable
              v-model:value="item.field"
              @input:value="onFieldChange(i)"
              :options="conditionFields"
            >
            </select-field>
          </n-gi>
          <n-gi cols="3">
            <select-field
              label="Value"
              v-model:value="item.value"
              :options="selectFieldOptions(item)"
              v-if="isSelectField(item)"
            />
            <input-field label="Value" v-model:value="item.value" v-else />
          </n-gi>
          <n-gi cols="3">
            <select-field
              label="Condition"
              :options="getConditions(item)"
              v-model:value="item.condition"
            ></select-field>
          </n-gi>
          <n-gi cols="3" class="d-flex">
            <select-field
              label="Type"
              v-model:value="item.type"
              :options="conditionTypes"
            />
            <delete-icon-button
              @click="remove(i)"
              class="ml-2 mr-2 mt-6"
            ></delete-icon-button>
          </n-gi>
        </n-grid>

        <add-button class="mb-3 mt-3" @click="addCondition()" small
          >AND/OR</add-button
        >
        <div class="title mb-3">Then</div>
        <v-row>
          <v-col>
            <select-field
              label="Action"
              v-model:value="formCondition.action"
              :options="actions"
            />
          </v-col>
          <v-col>
            <select-field
              label="Field"
              :filterable="true"
              :options="filterFields"
              v-model:value="formCondition.field"
            />
          </v-col>
        </v-row>
        <div class="d-flex mt-3">
          <div class="flex-1"></div>
          <close-button @click="dialog = false"></close-button>
          <primary-button class="ml-3" attr-type="submit"
            >Submit</primary-button
          >
        </div>
      </form-wrapper>
    </n-modal>
  </div>
</template>

<script>
import {
  conditionalFields,
  fieldTypes,
  optionConditions,
  optionFields,
  textConditions,
} from "../../assets/constants/form.builder.constant.js";
import AddButton from "../ui/buttons/AddButton.vue";
import DeleteIconButton from "../ui/buttons/DeleteIconButton.vue";
import CloseButton from "../ui/buttons/CloseButton.vue";
import PrimaryButton from "../ui/buttons/PrimaryButton.vue";
import EditIconButton from "../ui/buttons/EditIconButton.vue";
import FormWrapper from "../ui/form/FormWrapper.vue";
import InputField from "../ui/form/InputField.vue";
import SelectField from "../ui/form/SelectField.vue";
import formMixins from "../../mixins/form.mixins.js";
import { FormCondition } from "../../assets/model/form.master.model.js";
import formBuilderService from "../../assets/services/form.builder.service.js";
import { getSettings } from "../../assets/js/common.helper.js";
let whitelist = [...optionFields, "checkbox"];

export default {
  components: {
    SelectField,
    AddButton,
    InputField,
    FormWrapper,
    EditIconButton,
    DeleteIconButton,
    PrimaryButton,
    CloseButton,
  },
  emits: ["close", "open"],
  props: {
    condition: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      dialog: false,
      bodyStyle: { maxWidth: "1000px" },
      fields: [],
      formCondition: {
        conditions: [],
        field: "",
        action: "",
      },
    };
  },
  mixins: [formMixins],
  computed: {
    isUpdate() {
      return new FormCondition(this.condition).Formula_Id != 0;
    },
    conditionFields() {
      return [...this.fields].filter((item) => {
        return conditionalFields.indexOf(item.type) != -1;
      });
    },
    filterFields() {
      let fields = {};
      this.formCondition.conditions.forEach((item) => {
        fields[item.field] = true;
      });
      return [...this.fields].filter((item) => {
        return fields[item.value] == undefined;
      });
    },
    conditionTypes() {
      return ["AND", "OR"].map((label) => ({ label, value: label }));
    },
    actions() {
      return ["Show", "Hide"].map((label) => ({ label, value: label }));
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        this.init();
        this.$emit("close");
      } else {
        this.$emit("open");
      }
    },
  },
  methods: {
    async getFields() {
      let list = [...this.formFields];
      let fields = [];
      for (let item of list) {
        item = { ...item };
        let { step } = this.getStep(item);
        let doc = {};
        doc.label = await this.getLabel(item);
        // doc.label = `Step ${step} - ` + label;
        doc.step = step;
        doc.type = item.Field_Type;
        doc.settings = item.Form_settings;
        doc.Form_settings = item.Form_settings;
        doc.value = item.Field_Id;
        fields.push(doc);
      }
      fields = fields.sort((a, b) => {
        return a.step - b.step;
      });
      this.fields = fields;
    },
    remove(i) {
      this.$confirm({
        message: "Are you sure, Do you want to remove this condition?",
        onConfirm: () => {
          this.formCondition.conditions.splice(i, 1);
        },
      });
    },

    getLabel({ type, Parent, Field_Id, Form_Label }) {
      return new Promise((resolve) => {
        let parent = Parent;
        let labels = [Form_Label];
        let fields = [...this.formFields].filter((i) => i.Field_Id != Field_Id);
        if (parent != 0) {
          while (parent) {
            let field = fields.find((i) => i.Field_Id == parent);
            if (field) {
              parent = field.Parent == 0 ? null : field.Parent;
              if (field.Form_Label) labels.push(field.Form_Label);
            } else {
              parent = null;
            }
          }
        }
        if (labels.length > 0) {
          let label = labels.reverse().toString().split(",").join(" - ");
          resolve(label);
          return;
        }
        let doc = fieldTypes.find((item) => {
          return item.value == type;
        });
        resolve(doc ? doc.label : "");
      });
    },
    isSelectField(item) {
      let field = this.fields.find((f) => {
        return f.value == item.field;
      });
      return whitelist.indexOf(field?.type) != -1;
    },
    selectFieldOptions(item) {
      let field = this.fields.find((f) => {
        return f.value == item.field;
      });
      if (field?.type == "checkbox")
        return ["Checked"].map((label) => ({
          label,
          value: label,
        }));
      let settings = getSettings({ ...field });
      let list = settings.options || [];
      return list.map((label) => ({ label, value: label }));
    },
    getConditions(item) {
      let field = this.fields.find((f) => {
        return f.value == item.field;
      });
      let conditions = textConditions;
      if (whitelist.indexOf(field?.type) != -1) conditions = optionConditions;
      return conditions.map((label) => ({ label, value: label }));
    },
    getStep({ Step_id }) {
      let index = [...this.formSteps].findIndex((item) => {
        return item.Step_id == Step_id;
      });
      if (index <= 0) index = 0;
      return { step: index + 1 };
    },
    onFieldChange(i) {
      let field = this.formCondition.conditions[i].field;
      if (field == this.formCondition.field) this.formCondition.field = "";
      this.formCondition.conditions[i].value = "";
      this.formCondition.conditions[i].condition = "";
    },
    addCondition() {
      this.formCondition.conditions.push({
        type: "AND",
        value: "",
        condition: "",
        field: "",
        ts: new Date().getTime(),
      });
    },
    init() {
      const vm = this;
      vm.getFields();
      if (!vm.isUpdate) {
        vm.formCondition = {
          conditions: [],
          field: "",
          action: "",
        };
        return vm.addCondition();
      }
      let condition = new FormCondition(vm.condition);
      console.log(condition);
      vm.formCondition.conditions = JSON.parse(condition.Form_Condition);
      vm.formCondition.field = condition.Field_Id;
      vm.formCondition.action = condition.Action;
    },
    async submit() {
      const vm = this;
      try {
        let { conditions, field, action } = vm.formCondition;
        let doc = new FormCondition({
          Form_Condition: JSON.stringify(conditions),
          Field_Id: field,
          Action: action,
          Form_id: vm.formData.Formid,
          Dml_Indicator: "IS",
        });
        vm.$loader.show();
        if (vm.isUpdate) {
          let id = vm.condition.Formula_Id;
          doc.Formula_Id = id;
          doc.Dml_Indicator = "UP";
        }
        await formBuilderService.formCondition(doc);
        await vm.fetchFormConditions();
        vm.$loader.hide();
        vm.dialog = false;
      } catch (error) {
        vm.handleError(error);
      }
    },
    formatCondition({ conditions }) {
      let html = ``;
      conditions.forEach((item) => {
        let field = this.findField(item.field);
        if (field != null) {
          html += `<b>${field.settings.label || "Field"} :</b> `;
          html += `${item.value} - ${item.condition}<br/>`;
          if (conditions.length != 1) {
            html += `<div class="condition-chip primary">${item.type}</div>`;
          }
        }
      });
      return html;
    },
    findField(id) {
      return this.draftFields.find((item) => {
        return item.id == id;
      });
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style></style>
