<template>
  <div class="d-flex">
    <signature-field :field="field" :label="label" :help="settings.help_text" />
    <div>
      <fb-field-config :field="field"></fb-field-config>
    </div>
  </div>
</template>

<script>
import { getSettings } from "../../assets/js/common.helper";
import SignatureField from "../ui/form/SignatureField.vue";
import FbFieldConfig from "./FbFieldConfig.vue";
export default {
  props: { field: {} },
  components: { FbFieldConfig, SignatureField },
  computed: {
    label() {
      return this.field.Form_Label;
    },
    settings() {
      return getSettings({ ...this.field });
    },
    type() {
      return this.field.Field_Type;
    },
  },
};
</script>

<style>
</style>