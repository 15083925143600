<template>
  <n-form-item
    :span="12"
    :path="path"
    :label="label"
    :label-style="labelStyle"
    class="flex-1"
    :rule="rule"
  >
    <template v-slot:label>
      <span class="mr-1">
        {{ label }}
      </span>
      <help-text v-if="help"> {{ help }}</help-text>
    </template>
    <n-radio-group
      v-bind="$attrs"
      v-model:value="input"
      @update:value="$emit('input', input)"
      clearable
      @keydown.enter.prevent
    >
      <n-space>
        <n-radio v-for="(option, i) in options" :key="i" :value="option.value">
          {{ option.label }}
        </n-radio>
      </n-space>
    </n-radio-group>
  </n-form-item>
</template>

<script>
import HelpText from "../HelpText.vue";

export default {
  components: { HelpText },
  inheritAttrs: false,
  props: {
    value: {},
    help: { type: String, default: "" },
    path: {},
    options: {
      type: Array,
      default: () => [],
    },
    customRules: {
      type: Array,
      default: () => [],
    },
    rules: {
      type: String,
      default: "",
    },
    label: { type: String, default: "" },
    settings: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      input: "",
    };
  },
  computed: {
    rule() {
      let { rules } = this;
      const vm = this;
      let settings = this.settings || {};
      return {
        trigger: ["submit", "blur", "input"],
        required: settings.is_required || false,
        validator() {
          for (let rule of rules) {
            let msg = rule({ v: vm.input, ...vm.settings });
            if (msg) return new Error(msg);
          }
        },
      };
    },
  },
  mounted() {
    this.input = this.value;
  },
};
</script>

<style>
</style>