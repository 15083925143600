class FormPreviewMaster {
  constructor({ ft, s, steps, conditions, fields }) {
    steps = steps || [];
    conditions = conditions || [];
    this.Form_Type = ft;
    this.Steps = s;
    this.steps = steps.map((i) => new FormPreviewStep(i));
    this.conditions = conditions.map((i) => new FormPreviewCondition(i));
    this.fields = fields.map((i) => new FormPreviewField(i));
  }
}
class FormPreviewStep {
  constructor({ id, p, n }) {
    this.Step_id = id;
    this.Step_Position = p;
    this.Step_name = n;
  }
}
class FormPreviewCondition {
  constructor({ id, a, c, f }) {
    c = c || [];
    this.Formula_Id = id;
    this.Field_Id = f;
    this.conditions = c.map((i) => new FormPreviewConditionItem(i));
    this.Action = a;
  }
}

class FormPreviewField {
  constructor({ sId, id, s, l, t, po, p }) {
    this.Step_id = sId;
    this.Field_Id = id;
    this.Form_settings = new FormPreviewSettings(s);
    this.Form_Label = l;
    this.Field_Type = t;
    this.Field_Position = po;
    this.Parent = p;
  }
}

class FormPreviewSettings {
  constructor({ p, d, ir, im, isf, mic, mac, ms, mf, ft, h, o, ic, html, c, col, iv, u, lf, lc }) {
    this.placeholder = p;
    this.default_value = d;
    this.is_required = ir;
    this.is_collapsible = ic;
    this.is_multiple = im;
    this.is_future = isf;
    this.min_char = mic;
    this.max_char = mac;
    this.max_size = ms;
    this.max_files = mf;
    this.file_type = ft;
    this.help_text = h;
    this.html = html;
    this.options = o;
    this.character = c;
    this.column = col;
    this.is_visible = iv;
    this.unit = u;
    this.label_color = lc;
    this.label_font = lf;
  }
}
class FormPreviewConditionItem {
  constructor({ c, f, t, ts, v }) {
    this.condition = c;
    this.field = f;
    this.type = t;
    this.ts = ts;
    this.value = v;
  }
}

export {
  FormPreviewMaster,
  FormPreviewStep,
  FormPreviewField,
  FormPreviewCondition,
};
