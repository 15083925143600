class FormMaster {
  constructor({
    Dml_Indicator,
    Formid,
    Hospital_id,
    Branch_id,
    Form_Type,
    Form_Structure,
    Form_Name,
    Description,
    Created_By,
    Created_On,
    Updated_By,
    Updtated_On,
    Steps,
  }) {
    this.Dml_Indicator = Dml_Indicator || "SE";
    this.Formid = Formid || 0;
    this.Hospital_id = Hospital_id || 0;
    this.Branch_id = Branch_id || 0;
    this.Form_Type = Form_Type || "";
    this.Form_Structure = Form_Structure || "";
    this.Form_Name = Form_Name || "";
    this.Description = Description || "";
    this.Created_By = Created_By || 0;
    this.Created_On = Created_On || "2022-09-02";
    this.Updated_By = Updated_By || 0;
    this.Updtated_On = Updtated_On || "2022-09-02";
    this.Steps = Steps || 0;
  }
}
class FormStep {
  constructor({ Dml_Indicator, Form_id, Step_id, Step_Position, Step_name }) {
    this.Dml_Indicator = Dml_Indicator || "SE";
    this.Form_id = Form_id || 0;
    this.Step_id = Step_id || 0;
    this.Step_Position = Step_Position || 0;
    this.Step_name = Step_name || "";
  }
}
class FormCondition {
  constructor({
    Dml_Indicator,
    Form_id,
    Field_Id,
    Action,
    Form_Condition,
    Formula_Id,
  }) {
    this.Dml_Indicator = Dml_Indicator || "SE";
    this.Formula_Id = Formula_Id || 0;
    this.Form_id = Form_id || 0;
    this.Field_Id = Field_Id || 0;
    this.Form_Condition = Form_Condition || "";
    this.Action = Action || "";
  }
}

class FormField {
  constructor({
    Dml_Indicator,
    Field_Id,
    Step_id,
    Form_id,
    Form_settings,
    Form_Label,
    Field_Type,
    Field_Position,
    Parent,
  }) {
    this.Dml_Indicator = Dml_Indicator || "SE";
    this.Step_id = Step_id || 0;
    this.Form_id = Form_id || 0;
    this.Field_Id = Field_Id || 0;
    this.Form_settings = Form_settings
      ? JSON.stringify(Form_settings)
      : JSON.stringify({});
    this.Form_Label = Form_Label || "";
    this.Field_Type = Field_Type || 0;
    this.Field_Position = Field_Position || 0;
    this.Parent = Parent || 0;
  }
}
class FormSubmitData {
  constructor({
    Dml_Indicator,
    Id,
    Formid,
    Versionid,
    Responsedata,
    responsedataweb,
    Createdby,
    Updatedby,
  }) {
    this.Dml_Indicator = Dml_Indicator || "SE";
    this.Id = Id || 0;
    this.Formid = Formid || 0;
    this.Versionid = Versionid || 0;
    this.Responsedata = Responsedata || "";
    (this.responsedataweb = responsedataweb || ""),
      (this.Createdby = Createdby || 0);
    this.Updatedby = Updatedby || 0;
  }
}

export { FormMaster, FormStep, FormField, FormCondition, FormSubmitData };
