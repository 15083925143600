<template>
  <n-popover trigger="hover">
    <template #trigger>
      <n-icon size="medium" v-bind="$attrs">
        <HelpOutlineTwotone />
      </n-icon>
    </template>
    <span>
      <slot></slot>
    </span>
  </n-popover>
</template>

<script>
import { HelpOutlineTwotone } from "@vicons/material";

export default {
  inheritAttrs: false,
  components: { HelpOutlineTwotone },
};
</script>

<style>
</style>