<template>
  <div>
    <edit-icon-button
      v-if="stepId"
      size="small"
      @click="dialog = true"
    ></edit-icon-button>
    <add-button v-else @click="dialog = true">Add New Step</add-button>
    <n-modal
      v-model:show="dialog"
      class="custom-card"
      preset="card"
      :title="title"
      :style="bodyStyle"
      :bordered="false"
      :closeOnEsc="false"
      :maskClosable="false"
      :closable="false"
    >
      <form-wrapper @onSubmit="submit()" :rules="rules" :model="form">
        <input-field
          path="Step_name"
          v-model:value="form.Step_name"
          label="Step Name"
        ></input-field>
        <input-field
          path="Step_Position"
          type="number"
          v-model:value="form.Step_Position"
          label="Step Position"
        ></input-field>
        <template v-slot:footer>
          <close-button @click="dialog = false"></close-button>
          <primary-button :loading="loading" attr-type="submit"
            >Submit</primary-button
          >
        </template>
      </form-wrapper>
    </n-modal>
  </div>
</template>

<script>
import { FormMaster, FormStep } from "../../assets/model/form.master.model";
import AddButton from "../ui/buttons/AddButton.vue";
import FormWrapper from "../ui/form/FormWrapper.vue";
import InputField from "../ui/form/InputField.vue";
import PrimaryButton from "../ui/buttons/PrimaryButton.vue";
import rulesConstant from "../../assets/constants/rules.constant";
import CloseButton from "../ui/buttons/CloseButton.vue";
import formBuilderService from "../../assets/services/form.builder.service";
import formMixins from "../../mixins/form.mixins";
import EditIconButton from "../ui/buttons/EditIconButton.vue";
export default {
  props: {
    step: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    AddButton,
    FormWrapper,
    InputField,
    PrimaryButton,
    CloseButton,
    EditIconButton,
  },
  data() {
    return {
      dialog: false,
      loading: false,
      rules: {
        Step_name: rulesConstant.required,
        Step_Position: rulesConstant.required,
      },
      form: {
        Step_name: "",
        Step_Position: 0,
      },
      bodyStyle: { width: "400px" },
    };
  },
  mixins: [formMixins],
  computed: {
    stepId() {
      return new FormStep(this.step).Step_id;
    },
    title() {
      return this.stepId ? "Update Step" : "Add Step";
    },
  },
  watch: {
    dialog() {
      this.init();
    },
  },
  methods: {
    async submit() {
      const vm = this;
      try {
        vm.loading = true;
        let formId = new FormMaster(vm.formData).Formid;
        let doc = new FormStep({
          ...vm.form,
          Dml_Indicator: "IS",
          Form_id: formId,
        });
        if (vm.stepId) {
          doc.Step_id = vm.stepId;
          doc.Dml_Indicator = "UP";
        }
        let { status, msg } = (await formBuilderService.formStep(doc)).data;
        if (!status) {
          vm.loading = false;
          vm.$alert(msg);
          return;
        }
        await vm.fetchFormSteps(formId);
        if (vm.formSteps.length == 1) {
          vm.$store.commit("setFormStep", vm.formSteps[0]);
        }
        vm.dialog = false;
        vm.form = { Step_name: "", Step_Position: 0 };
        vm.loading = false;
      } catch (error) {
        vm.handleError(error);
      }
    },
    init() {
      const vm = this;
      Object.keys(vm.form).forEach((key) => {
        if (vm.step[key] != undefined) vm.form[key] = vm.step[key];
      });
      if (!vm.stepId) {
        vm.form.Step_Position = vm.formSteps.length + 1;
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style>
</style>