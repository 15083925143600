<template>
  <n-steps :current="currentStep">
    <n-step v-for="(step, i) in formSteps" :key="i">
      <template #title>
        <div class="ml-1">
          {{ step.Step_name }}
        </div>
      </template>
    </n-step>
  </n-steps>
</template>

<script>
import formMixins from "../../mixins/form.mixins";
import { FormMaster, FormStep } from "../../assets/model/form.master.model";
import formBuilderService from "../../assets/services/form.builder.service";
export default {
  props: {
    currentStep: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      dialog: true,
    };
  },
  mixins: [formMixins],
  methods: {
    deleteStep(item) {
      const vm = this;
      vm.$confirm({
        message: "Are you sure, Do you want to remove this step?",
        onConfirm: async () => {
          try {
            vm.$loader.show();
            let formId = new FormMaster(vm.formData).Formid;
            let step = new FormStep({
              ...item,
              Dml_Indicator: "D",
              Form_id: formId,
            });
            await formBuilderService.formStep(step);
            await vm.fetchFormSteps(formId);
            vm.$loader.hide();
          } catch (error) {
            vm.handleError(error);
          }
        },
        onClose: () => {
          console.log("Close");
        },
      });
    },
  },
};
</script>

<style>
</style>