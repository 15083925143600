<template>
  <n-button quaternary circle v-bind="$attrs">
    <template #icon>
      <n-icon>
        <slot></slot>
      </n-icon>
    </template>
  </n-button>
</template>

<script>
export default {
  inheritAttrs: false,
};
</script>

<style>
</style>