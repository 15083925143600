<template>
  <div class="d-flex items-center">
    <fb-field-settings :field="field" v-if="isSettings"></fb-field-settings>
    <delete-icon-button
      size="small"
      @click="removeField()"
    ></delete-icon-button>
  </div>
</template>

<script>
import formBuilderService from "../../assets/services/form.builder.service";
import formMixins from "../../mixins/form.mixins";
import DeleteIconButton from "../ui/buttons/DeleteIconButton.vue";
import FbFieldSettings from "./FbFieldSettings.vue";
import { FormField } from "../../assets/model/form.master.model";
export default {
  props: {
    field: {},
    isSettings: { type: Boolean, default: true },
  },
  mixins: [formMixins],
  components: { FbFieldSettings, DeleteIconButton },
  methods: {
    async removeField() {
      const vm = this;
      vm.$confirm({
        message: "Are you sure, Do you want to remove this field?",
        onConfirm: async () => {
          try {
            vm.$loader.show();
            let doc = new FormField({ ...vm.field, Dml_Indicator: "D" });
            let { status, msg } = (await formBuilderService.formField(doc))
              .data;
            if (!status) {
              vm.$loader.hide();
              vm.$alert(msg);
              return;
            }
            await vm.fetchFormFields();
            vm.$loader.hide();
          } catch (error) {
            vm.handleError(error);
          }
        },
      });
    },
  },
};
</script>

<style>
</style>