<template>
  <div>
    <f-preview
      v-for="field in fieldList"
      :field="field"
      :parentPath="getParentPath"
      v-bind:key="field.Field_Id"
    />
  </div>
</template>

<script>
import { FormField } from "../../assets/model/form.master.model";
import formMixins from "../../mixins/form.mixins";
import { getSettings } from "../../assets/js/common.helper";
export default {
  props: {
    label: { type: String, default: "" },
    field: {},
  },
  data() {
    return {
      isOpen: true,
    };
  },
  mixins: [formMixins],
  computed: {
    isCollapsible() {
      let settings = getSettings({ ...this.field });
      return settings.is_collapsible == true;
    },
    parent() {
      return new FormField(this.field).Field_Id;
    },
    fieldList() {
      return [...this.formStepFields]
        .filter((i) => i.Parent == this.parent)
        .map((i) => ({ ...i }));
    },
  },
  methods: {
    openSection() {
      if (!this.isCollapsible) return;
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

<style>
/* .smooth-enter-active,
.smooth-leave-active {
  transition: max-height 0.5s;
}
.smooth-enter,
.smooth-leave-to {
  max-height: 0;
} */
.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.25s ease-out;
}

.slide-up-enter-from {
  opacity: 0;
  transform: translateY(30px);
}

.slide-up-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}
</style>