<template>
  <div class="d-flex items-center justify-center">
    <img src="../../assets/images/loader.svg" width="40" />
    <span class="ml-1"> Please wait... </span>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>