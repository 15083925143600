<template>
  <n-form-item
    :path="path"
    :label-style="labelStyle"
    :label="label"
    class="flex-1"
  >
    <n-checkbox-group
      v-bind="$attrs"
      v-model:value="input"
      @update:value="onChecked"
      @keydown.enter.prevent
      :max="max"
    >
      <n-space item-style="display: flex;">
        <n-checkbox
          v-for="(item, i) in options"
          :value="item.value"
          :key="i"
          :label="item.value"
        />
      </n-space>
    </n-checkbox-group>
  </n-form-item>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    value: {},
    showLabel: { type: Boolean, default: false },
    path: {},
    options: {
      type: Array,
      default: () => [],
    },
    customRules: {
      type: Array,
      default: () => [],
    },
    rules: {
      type: Array,
      default: () => [],
    },
    multiple: { type: Boolean, default: false },
    label: { type: String, default: "" },
    field: { type: Object, default: () => {} },
  },
  data() {
    return {
      input: this.multiple ? [] : "",
    };
  },
  computed: {
    max() {
      return this.multiple ? this.options.length : 1;
    },
    rule() {
      let { rules } = this;
      const vm = this;
      let settings = this.settings || {};
      return {
        trigger: ["submit", "blur", "input"],
        required: settings.is_required || false,
        validator() {
          for (let rule of rules) {
            let msg = rule({ v: vm.input, ...vm.settings });
            if (msg) return new Error(msg);
          }
        },
      };
    },
  },
  methods: {
    onChecked(val) {
      this.$emit("input", val);
    },
  },
  mounted() {
    this.input = this.value || this.multiple ? [] : "";
  },
};
</script>

<style>
</style>