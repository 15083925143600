import Alert from "./Alert.vue";
import { createApp } from "vue";

export default {
  install(app) {
    const instance = createApp(Alert).mount(document.createElement('div'))

    app.config.globalProperties.$alert = (msg) => {
      instance.showAlert(msg)
    }

    app.config.globalProperties.$confirm = (params) => {
      instance.showConfirm(params)
    }
  }
};

