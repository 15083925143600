class FormPublishMaster {
  constructor({ Form_Type, Steps, steps, conditions, fields }) {
    steps = steps || [];
    conditions = conditions || [];
    this.ft = Form_Type || "";
    this.s = Steps || 0;
    this.steps = steps.map((i) => new FormPublishStep(i));
    this.conditions = conditions.map((i) => new FormPublishCondition(i));
    this.fields = fields.map((i) => new FormPublishField(i));
  }
}
class FormPublish {
  constructor({
    Dml_Indicator,
    Version_Id,
    Form_id,
    Version_No,
    File_Location,
  }) {
    this.Dml_Indicator = Dml_Indicator || "SE";
    this.Version_Id = Version_Id || 0;
    this.Form_id = Form_id;
    this.Version_No = Version_No || "";
    this.File_Location = File_Location || "{}";
  }
}
class FormPublishStep {
  constructor({ Step_id, Step_Position, Step_name }) {
    this.id = Step_id || 0;
    this.p = Step_Position || 0;
    this.n = Step_name || "";
  }
}
class FormPublishCondition {
  constructor({ Field_Id, Action, Form_Condition, Formula_Id }) {
    let conditions = {};
    try {
      conditions = JSON.parse(Form_Condition);
      conditions = conditions.map((i) => new FormPublishConditionItem(i));
    } catch (error) {
      console.log(error);
    }
    this.id = Formula_Id || 0;
    this.f = Field_Id || 0;
    this.c = conditions;
    this.a = Action || "";
  }
}

class FormPublishField {
  constructor({
    Field_Id,
    Step_id,
    Form_settings,
    Form_Label,
    Field_Type,
    Field_Position,
    Parent,
  }) {
    this.sId = Step_id || 0;
    this.id = Field_Id || 0;
    try {
      Form_settings = JSON.parse(Form_settings);
    } catch (error) {
      console.log(error.message);
    }
    this.s = new FormPublishSettings(Form_settings) || {};
    this.l = Form_Label || "";
    this.t = Field_Type || 0;
    this.po = Field_Position || 0;
    this.p = Parent || 0;
  }
}

class FormPublishSettings {
  constructor({
    placeholder,
    default_value,
    is_required,
    is_multiple,
    is_future,
    min_char,
    max_char,
    max_size,
    max_files,
    file_type,
    help_text,
    options,
    html,
    character,
    is_collapsible,
    column,
    is_visible,
    unit,
    label_font,
    label_color
  }) {
    this.p = placeholder;
    this.d = default_value;
    this.ir = is_required || false;
    this.ic = is_collapsible || false;
    this.im = is_multiple || false;
    this.isf = is_future || false;
    this.mic = min_char;
    this.mac = max_char;
    this.ms = max_size;
    this.mf = max_files;
    this.ft = file_type;
    this.h = help_text;
    this.o = options;
    this.c = character;
    this.col = column;
    this.html = html || undefined;
    this.iv = is_visible
    this.u = unit;
    this.lf = label_font;
    this.lc = label_color
  }
}
class FormPublishConditionItem {
  constructor({ condition, field, type, ts, value }) {
    this.c = condition;
    this.f = field;
    this.t = type;
    this.ts = ts;
    this.v = value;
  }
}

export {
  FormPublishMaster,
  FormPublishStep,
  FormPublishField,
  FormPublishCondition,
  FormPublish,
};
