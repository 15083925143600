import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import naive from "naive-ui";
import "./assets/scss/helpers.scss";
import commonMixins from "./mixins/common.mixins";
import alert from "./components/ui/alert/index.js";
import mitt from "mitt";
import loader from "./components/ui/loader";
import FbPreview from "./components/formbuilder/FbPreview.vue";
import FPreview from "./components/formPreview/FPreview.vue";
import VueSignaturePad from "vue-signature-pad";

const emitter = mitt();

const app = createApp(App);
app.component("fb-preview", FbPreview);
app.component("f-preview", FPreview);
app.config.globalProperties.emitter = emitter;
app.use(store);
app.use(router);
app.mixin(commonMixins);
app.use(alert);
app.use(loader);
app.use(VueSignaturePad);
app.use(naive);
app.mount("#app");
