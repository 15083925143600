<template>
  <n-form-item
    :label="label"
    :label-style="labelStyle"
    :path="path"
    class="flex-1"
    :rule="rule"
  >
    <template v-slot:label>
      <span class="mr-1">
        {{ label }}
      </span>
      <help-text v-if="help"> {{ help }}</help-text>
    </template>
    <n-upload
      v-bind="$attrs"
      v-model:file-list="input"
      :multiple="multiple"
      clearable
      :accept="accept"
      :placeholder="placeholder"
      :max="max"
      @change="onFileChange"
      @keydown.enter.prevent
    >
      <outlined-button> Select File </outlined-button>
    </n-upload>
  </n-form-item>
</template>

<script>
import OutlinedButton from "../buttons/OutlinedButton.vue";
import HelpText from "../HelpText.vue";

export default {
  components: { HelpText, OutlinedButton },
  inheritAttrs: false,
  props: {
    field: { type: Object, default: () => ({}) },
    value: {},
    help: { type: String, default: "" },
    type: { type: String, default: "text" },
    placeholder: { type: String, default: "" },
    path: {},
    customRules: {
      type: Array,
      default: () => [],
    },
    rules: {
      type: Array,
      default: () => [],
    },
    settings: {
      type: Object,
      default: () => ({}),
    },
    file_type: {
      type: Array,
      default: () => [],
    },
    label: { type: String, default: "" },
  },
  data() {
    return {
      input: [],
    };
  },
  computed: {
    accept() {
      return this.file_type ? this.file_type.toString() : "";
    },
    rule() {
      let { rules } = this;
      const vm = this;
      let settings = this.settings || {};
      return {
        trigger: ["submit", "blur", "input"],
        required: settings.is_required || false,
        validator() {
          for (let rule of rules) {
            let msg = rule({ v: vm.input, ...vm.settings });
            if (msg) return new Error(msg);
          }
        },
      };
    },
    max() {
      let { max_files } = this.settings;
      return max_files || 1;
    },
    multiple() {
      let { max_files } = this.settings;
      return max_files && max_files > 1;
    },
  },
  watch: {
    value(v) {
      this.input = v;
    },
  },
  methods: {
    onFileChange() {
      let size = this.settings.max_size;
      let file_type = this.file_type || [];
      this.$nextTick(() => {
        let allowed = [];
        let notAllowed = [];
        let fileTypeNotAllowed = [];
        let files = this.input.filter((i) => {
          if (i.file && file_type) {
            let isExt = file_type.find((type) => i.name.endsWith(type));
            if (isExt == null) fileTypeNotAllowed.push(i.name);
            return isExt != null;
          }
          return true;
        });

        files.forEach((i) => {
          if (i.file && size) {
            let sizeBytes = size * 1000000;
            if (i.file.size < sizeBytes) {
              allowed.push(i);
            } else {
              notAllowed.push(i.name);
            }
          } else {
            allowed.push(i);
          }
        });
        if (notAllowed.length) {
          this.$alert(
            `File size not allowed for below files <br/>${notAllowed
              .toString()
              .split(",")
              .join("<br/>")}`
          );
        }
        if (fileTypeNotAllowed.length) {
          this.$alert(
            `File type not allowed for below files <br/>${fileTypeNotAllowed
              .toString()
              .split(",")
              .join("<br/>")}`
          );
        }
        this.input = allowed;
        this.$emit("input", allowed);
      });
    },
  },
  mounted() {
    if (this.value instanceof Array) {
      this.input = this.value;
    }
  },
};
</script>

<style lang="scss">
.input-feedback {
  .n-form-item {
    grid-template-rows: none !important;
  }
  .n-form-item-blank,
  .n-form-item-label {
    display: none !important;
  }
}
</style>