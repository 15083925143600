<template>
  <layout class="p-5">
    <please-wait v-if="loading"></please-wait>
    <div
      v-else-if="error"
      class="d-flex justify-center items-center"
      style="height: 100vh"
    >
      <n-result status="error" title="Error" :description="error"></n-result>
    </div>
    <template v-else>
      <fb-header :id="formId"></fb-header>
      <fb-steps v-if="isStepForm"></fb-steps>
      <div class="mt-3">
        <n-form :model="form" :rules="formRules">
          <draggable
            v-model="fieldList"
            item-key="Field_Id"
            @change="onPositionChange"
          >
            <template #item="{ element }">
              <fb-preview class="form-item" :field="element" />
            </template>
          </draggable>
        </n-form>
      </div>
      <FbAddField v-if="isStepForm && formStep.Step_id"></FbAddField>
      <FbAddField v-if="!isStepForm"></FbAddField>
    </template>
  </layout>
</template>

<script>
import FbSteps from "../components/formbuilder/FbSteps.vue";
import FbHeader from "../components/formbuilder/FbHeader.vue";
import PleaseWait from "../components/ui/PleaseWait.vue";
import formMixins from "../mixins/form.mixins";
import FbAddField from "../components/formbuilder/FbAddField.vue";
import FbPreview from "../components/formbuilder/FbPreview.vue";
import draggable from "vuedraggable";
import Layout from "../components/ui/Layout.vue";
export default {
  components: {
    FbSteps,
    FbHeader,
    PleaseWait,
    FbAddField,
    FbPreview,
    draggable,
    Layout,
  },
  data() {
    return {
      loading: true,
      error: "",
      fieldList: [],
    };
  },
  mixins: [formMixins],
  computed: {
    formId() {
      return this.$route.params.id;
    },
  },
  watch: {
    formStepFields(val) {
      this.fieldList = [...val]
        .filter((i) => i.Parent == 0)
        .map((i) => ({ ...i }));
    },
  },
  methods: {
    async fetchForm() {
      const vm = this;
      try {
        vm.loading = true;
        await vm.fetchFormData(vm.formId);
        if (vm.isStepForm) {
          await vm.fetchFormSteps();
          if (vm.formSteps.length) {
            vm.$store.commit("setFormStep", vm.formSteps[0]);
          }
        }
        await vm.fetchFormFields();
        await vm.fetchFormConditions();
        vm.loading = false;
      } catch (error) {
        vm.error = error.message;
        vm.loading = false;
      }
    },
  },
  mounted() {
    this.$store.commit("resetForm");
    this.fetchForm();
  },
};
</script>

<style lang="scss">
.form-item {
  cursor: move;
  label {
    cursor: move;
  }
}
</style>