<template>
  <div v-if="html" v-html="html"></div>
</template>

<script>
export default {
  props: {
    html: {},
  },
};
</script>

<style>
</style>