<template>
  <n-modal
    v-model:show="dialog"
    :closeOnEsc="false"
    :maskClosable="false"
    :closable="false"
  >
    <n-card
      style="width: 200px"
      :bordered="false"
      role="dialog"
      :style="bodyStyle"
    >
      <div class="d-flex items-center justify-center">
        <img src="../../../assets/images/loader.svg" width="40" />
        <span class="ml-1"> Please wait... </span>
      </div>
    </n-card>
  </n-modal>
</template>
<script>
import { NCard, NModal } from "naive-ui";
export default {
  components: {
    NCard,
    NModal,
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    show() {
      this.dialog = true;
    },
    hide() {
      this.dialog = false;
    },
  },
};
</script>

<style></style>
