<template>
  <div class="d-flex">
    <n-card class="flex-grow-1 pa-4" flat>
      <div class="flex-1" :style="labelStyle">
        {{ field.Form_Label }}
      </div>
      <vue-editor
        class="mt-1"
        ref="editor"
        :use-save-button="true"
        v-model="html"
        :editor-toolbar="customToolbar"
      ></vue-editor>
    </n-card>
    <fb-field-config
      :isSettings="isRichEditor"
      :field="field"
    ></fb-field-config>
  </div>
</template>
  
  <script>
import formBuilderService from "../../assets/services/form.builder.service";
import { VueEditor, Quill } from "vue3-editor";
import FbFieldConfig from "./FbFieldConfig.vue";
import formMixins from "../../mixins/form.mixins";
const sizeStyle = Quill.import("attributors/style/size");
sizeStyle.whitelist = [
  "6px",
  "8px",
  "10px",
  "12px",
  "14px",
  "16px",
  "18px",
  "20px",
  "24px",
  "30px",
  "32px",
  "36px",
];
Quill.register(sizeStyle, true);
export default {
  props: {
    field: {},
  },
  components: { VueEditor, FbFieldConfig },
  data() {
    return {
      html: "",
    };
  },
  mixins: [formMixins],
  computed: {
    customToolbar() {
      return [
        [
          // { header: [false, 1, 2, 3, 4, 5, 6] },
          {
            size: [
              false,
              "6px",
              "8px",
              "10px",
              "12px",
              "14px",
              "16px",
              "18px",
              "20px",
              "24px",
              "30px",
              "32px",
              "36px",
            ],
          },
        ],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ["link", "video"],
        this.isRichEditor ? ["clean"] : ["clean", "image", "save"],
      ];
    },
    isRichEditor() {
      return this.field.Field_Type == "rich-editor";
    },
    settings() {
      let settings = this.field.Form_settings;
      try {
        return JSON.parse(settings);
      } catch (error) {
        console.log(error);
      }
      return {};
    },
  },
  methods: {
    async updateField() {
      const vm = this;
      try {
        let doc = { ...vm.field, Dml_Indicator: "UP" };
        vm.$loader.show();
        doc.Form_settings = JSON.stringify({ ...vm.settings, html: this.html });
        let { status, msg } = (await formBuilderService.formField(doc)).data;
        vm.$loader.hide();
        if (!status) {
          vm.$alert(msg);
          return;
        }
        await vm.fetchFormFields();
      } catch (error) {
        vm.handleError(error);
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      let button = this.$refs.editor.$el.querySelector(".ql-save");
      if (button) {
        button.addEventListener("click", () => {
          this.updateField();
        });
      }
    });
  },
  beforeMount() {
    this.html = this.settings.html || "";
  },
};
</script>
  
  <style>
.ql-picker-item[data-value="6px"]::before,
.ql-picker-label[data-value="6px"]::before {
  content: "6px" !important;
}
.ql-picker-item[data-value="8px"]::before,
.ql-picker-label[data-value="8px"]::before {
  content: "8px" !important;
}
.ql-picker-item[data-value="10px"]::before,
.ql-picker-label[data-value="10px"]::before {
  content: "10px" !important;
}

.ql-picker-item[data-value="12px"]::before,
.ql-picker-label[data-value="12px"]::before {
  content: "12px" !important;
}

.ql-picker-item[data-value="14px"]::before,
.ql-picker-label[data-value="14px"]::before {
  content: "14px" !important;
}

.ql-picker-item[data-value="16px"]::before,
.ql-picker-label[data-value="16px"]::before {
  content: "16px" !important;
}

.ql-picker-item[data-value="18px"]::before,
.ql-picker-label[data-value="18px"]::before {
  content: "18px" !important;
}

.ql-picker-item[data-value="20px"]::before,
.ql-picker-label[data-value="20px"]::before {
  content: "20px" !important;
}

.ql-picker-item[data-value="24px"]::before,
.ql-picker-label[data-value="24px"]::before {
  content: "24px" !important;
}

.ql-picker-item[data-value="30px"]::before,
.ql-picker-label[data-value="30px"]::before {
  content: "30px" !important;
}

.ql-picker-item[data-value="32px"]::before,
.ql-picker-label[data-value="32px"]::before {
  content: "32px" !important;
}

.ql-picker-item[data-value="36px"]::before,
.ql-picker-label[data-value="36px"]::before {
  content: "36px" !important;
}

.ql-save {
  width: 60px !important;
  color: white;
  margin-left: 10px;
  background: green !important;
}
.ql-save:hover {
  color: white !important;
}

.ql-save::before {
  content: "SAVE";
}
</style>
  