import axios from "axios";
let token =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIzNWRlNDg0OC00Njc1LTQ5ZjAtOTVhYy1kMjIxMDFkMDhlMWUiLCJ2YWxpZCI6IjEiLCJuYW1lIjoiYWRtaW5Ac2ltc2hvc3BpdGFsLmNvbSIsInBhc3N3b3JkIjoiMTIzNDEyMzQiLCJleHAiOjE2ODIwNTk0MjcsImlzcyI6Imh0dHA6Ly9teXNpdGUuY29tIiwiYXVkIjoiaHR0cDovL215c2l0ZS5jb20ifQ.V6zfxECqJNBDNPAmeTBpwPbIRsr08kR4cFI-GQRuIKI";
let http = axios.default.create({
  baseURL: "https://simsapi.convenientcare.life/api/Form_Builder",
  headers: {
    Authorization: `Bearer ${token}`,
  },
});
class FormBuilderService {
  formMaster(data) {
    return http.post("/insert_update_deleteFormMaster", data);
  }

  updatePosition(data) {
    return http.post("/insert_FormField", data);
  }

  formStep(data) {
    return http.post("/insert_update_deleteFormStep", data);
  }
  formCondition(data) {
    return http.post("/insert_update_deleteFormFormula", data);
  }
  formField(data) {
    return http.post("/insert_update_deleteFormField", data);
  }
  formPublish(data) {
    return http.post("/insert_update_deleteFormversion", data);
  }
  submitForm(data) {
    return http.post("/insert_update_deleteFormmasterdata", data);
  }
  getEditForm(params) {
    return http.get("/FormMasterData", { params });
  }
}

export default new FormBuilderService();
