<template>
  <n-button strong v-bind="$attrs" ghost>
    <template #icon>
      <n-icon v-if="icon" :component="icon"> </n-icon>
    </template>
    <slot></slot>
  </n-button>
</template>
  
  <script>
export default {
  inheritAttrs: false,
  props: {
    icon: {},
  },
};
</script>
  
  <style>
</style>