<template>
  <div class="d-flex">
    <check-box-group-field
      :showLabel="true"
      :options="options"
      :field="field"
      :label="label"
      :multiple="multiple"
    ></check-box-group-field>
    <fb-field-config :field="field"></fb-field-config>
  </div>
</template>

<script>
import { getSettings } from "../../assets/js/common.helper";
import CheckBoxGroupField from "../ui/form/CheckBoxGroupField.vue";
import FbFieldConfig from "./FbFieldConfig.vue";
export default {
  props: { field: {} },
  components: { FbFieldConfig, CheckBoxGroupField },
  computed: {
    label() {
      return this.field.Form_Label;
    },
    settings() {
      return getSettings({ ...this.field });
    },
    multiple() {
      return this.settings.is_multiple;
    },
    options() {
      let options = this.settings.options || [];
      return options.map((label) => ({ label, value: label }));
    },
  },
};
</script>

<style>
</style>