<template>
  <n-modal v-model:show="dialog">
    <n-card
      style="width: 400px"
      :title="title"
      :bordered="false"
      role="dialog"
      aria-modal="true"
    >
      <div v-html="message"></div>
      <template #footer>
        <div class="d-flex justify-flex-end">
          <n-button strong @click="close()" color="red">
            {{ type == "alert" ? "Close" : "No" }}
          </n-button>
          <n-button
            v-if="type != 'alert'"
            strong
            @click="submit()"
            color="green"
            class="ml-3"
          >
            Yes
          </n-button>
        </div>
      </template>
    </n-card>
  </n-modal>
</template>
<script>
import { NCard, NModal, NButton } from "naive-ui";
export default {
  components: {
    NCard,
    NModal,
    NButton,
  },
  data() {
    return {
      message: "",
      title: "",
      type: "alert",
      onConfirm: null,
      onClose: null,
      dialog: false,
    };
  },
  methods: {
    submit() {
      if (this.onConfirm) this.onConfirm();
      this.dialog = false;
    },
    close() {
      if (this.onClose) this.onClose();
      this.dialog = false;
    },
    showConfirm({ message, title, onConfirm, onClose }) {
      const vm = this;
      vm.type = "confirm";
      vm.message = message;
      vm.onConfirm = onConfirm;
      vm.onClose = onClose;
      vm.title = title || "Confirm";
      vm.dialog = true;
    },
    showAlert(message, title) {
      const vm = this;
      vm.type = "alert";
      vm.message = message;
      vm.onClose = null;
      vm.title = title || "Message";
      vm.dialog = true;
    },
  },
};
</script>

<style></style>
