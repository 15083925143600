<template>
  <div>
    <fb-text-field v-if="isTextField" :field="field" />
    <fb-date-field v-if="is(['date', 'datetime'])" :field="field" />
    <fb-time-field v-if="is(['time'])" :field="field" />
    <fb-radio-field v-if="is(['radio'])" :field="field" />
    <fb-checkbox-field v-if="is(['checkbox'])" :field="field" />
    <fb-checkbox-group-field v-if="is(['checkbox-group'])" :field="field" />
    <fb-select-field v-if="is(['select'])" :field="field" />
    <fb-file-upload-field v-if="is(['file-upload'])" :field="field" />
    <fb-section-field v-if="is(['section'])" :field="field" />
    <fb-grid-layout v-if="is(['grid'])" :field="field" />
    <fb-empty-cell v-if="is(['empty-cell'])" :field="field" />
    <fb-signature-field v-if="is(['signature'])" :field="field" />
    <fb-explanation v-if="is(['paragraph', 'rich-editor'])" :field="field" />
    <fb-container-field v-if="is(['container'])" :field="field" />
  </div>
</template>

<script>
import { textFields } from "../../assets/constants/form.builder.constant";
import FbCheckboxField from "./FbCheckboxField.vue";
import FbDateField from "./FbDateField.vue";
import FbEmptyCell from "./FbEmptyCell.vue";
import FbFileUploadField from "./FbFileUploadField.vue";
import FbGridLayout from "./FbGridLayout.vue";
import FbRadioField from "./FbRadioField.vue";
import FbSectionField from "./FbSectionField.vue";
import FbSelectField from "./FbSelectField.vue";
import FbSignatureField from "./FbSignatureField.vue";
import FbTextField from "./FbTextField.vue";
import FbExplanation from "./FbExplanation.vue";
import FbTimeField from "./FbTimeField.vue";
import FbCheckboxGroupField from "./FbCheckboxGroupField.vue";
import FbContainerField from "./FbContainerField.vue";
export default {
  components: {
    FbTextField,
    FbDateField,
    FbRadioField,
    FbCheckboxField,
    FbSelectField,
    FbFileUploadField,
    FbSectionField,
    FbGridLayout,
    FbEmptyCell,
    FbSignatureField,
    FbExplanation,
    FbTimeField,
    FbCheckboxGroupField,
    FbContainerField,
  },
  props: {
    field: {},
  },
  computed: {
    isTextField() {
      let skip = ["date", "datetime"];
      let fields = textFields.filter((i) => skip.indexOf(i) == -1);
      return ["0", ...fields].indexOf(this.type) != -1;
    },
    type() {
      return this.field.Field_Type;
    },
  },
  methods: {
    is(types) {
      return types.indexOf(this.type) != -1;
    },
  },
};
</script>

<style>
</style>