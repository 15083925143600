<template>
  <div class="d-flex">
    <file-field
      placeholder=""
      :label="label"
      :field="field"
      :help="settings.help_text"
    ></file-field>
    <fb-field-config :field="field"></fb-field-config>
  </div>
</template>

<script>
import { getSettings } from "../../assets/js/common.helper";
import FileField from "../ui/form/FileField.vue";
import FbFieldConfig from "./FbFieldConfig.vue";
export default {
  props: { field: {} },
  components: { FbFieldConfig, FileField },
  computed: {
    label() {
      return this.field.Form_Label;
    },
    settings() {
      return getSettings({ ...this.field });
    },
  },
};
</script>

<style>
</style>