import rulesConstant from "../assets/constants/rules.constant";
import { getSettings } from "../assets/js/common.helper";
import {
  FormCondition,
  FormField,
  FormMaster,
  FormStep,
} from "../assets/model/form.master.model";
import formBuilderService from "../assets/services/form.builder.service";

export default {
  computed: {
    form() {
      return this.$store.getters.form;
    },
    formRules() {
      return this.$store.getters.formRules;
    },
    formStepFields() {
      return [...this.formFields].filter((i) => {
        if (this.isStepForm)
          return i.Step_id == new FormStep(this.formStep).Step_id;
        return true;
      });
    },
    getParentPath() {
      let label = this.field.Form_Label || "";
      let path = this.parentPath;
      if (label && path) {
        path = `${path}.${label}`;
      } else if (label) {
        path = label;
      }
      return path;
    },
  },
  methods: {
    async onPositionChange() {
      let list = this.fieldList.map((item, i) => ({
        Dml_Indicator: "UPP",
        Field_Position: i + 1,
        Field_Id: item.Field_Id,
      }))
      const vm = this;
      try {
        vm.$loader.show();
        let { status, msg } = (await formBuilderService.updatePosition(list)).data
        if (!status) {
          vm.$loader.hide();
          vm.$alert(msg);
          return;
        }
        vm.$loader.hide();
      } catch (error) {
        vm.handleError(error);
      }
    },
    fetchFormData(formId) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        const vm = this;
        try {
          let doc = new FormMaster({ Formid: formId });
          let { status, data, msg } = (await formBuilderService.formMaster(doc))
            .data;
          if (status != true) {
            reject({ message: msg });
          } else if (data.Table == undefined || data.Table.length == 0) {
            reject({ message: '"Form not found!"' });
          } else {
            vm.$store.commit("setFormData", data.Table[0]);
            resolve();
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    fetchFormSteps() {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        const vm = this;
        try {
          let formId = new FormMaster(vm.formData).Formid;
          let doc = new FormStep({ Form_id: formId });
          let { status, data, msg } = (await formBuilderService.formStep(doc))
            .data;
          if (status != true) {
            reject({ message: msg });
          } else {
            vm.$store.commit("setFormSteps", data.Table || []);
            resolve();
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    fetchFormConditions() {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        const vm = this;
        try {
          let formId = new FormMaster(vm.formData).Formid;
          let doc = new FormCondition({ Form_id: formId });
          let { status, data, msg } = (
            await formBuilderService.formCondition(doc)
          ).data;
          if (status != true) {
            reject({ message: msg });
          } else {
            console.log("conditions", data.Table);
            vm.$store.commit("setFormConditions", data.Table || []);
            resolve();
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    fetchFormFields() {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        const vm = this;
        try {
          let formId = new FormMaster(vm.formData).Formid;
          let doc = new FormField({ Form_id: formId });
          let { status, data, msg } = (await formBuilderService.formField(doc))
            .data;
          if (status != true) {
            reject({ message: msg });
          } else {
            vm.$store.commit("setFormFields", data.Table || []);
            // vm.initFormRules();
            resolve();
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    initFormValues() {
      const vm = this;
      let form = { ...vm.form };
      vm.formFields.forEach((item) => {
        let field = new FormField(item);
        let { default_value } = getSettings({ ...item });
        let val = form[field.Field_Id];
        if (val == undefined && default_value != undefined)
          form[field.Field_Id] = default_value;
      });
      vm.$store.commit("setForm", form);
    },
    initFormRules() {
      let rules = {};
      this.formFields.forEach((item) => {
        let settings = getSettings({ ...item });
        let type = item.Field_Type;
        let { is_required } = settings;
        let id = item.Field_Id;
        if (is_required == true) {
          rules[id] = rulesConstant.required;
          if (type == "email") rules[id] = rulesConstant.email;
        } else if (type == "email") {
          rules[id] = rulesConstant.emailOptional;
        }
      });
      this.$store.commit("setFormRules", rules);
    },
  },
};
