<template>
  <n-button quaternary circle v-bind="$attrs" color="red">
    <template #icon>
      <n-icon>
        <delete-filled></delete-filled>
      </n-icon>
    </template>
  </n-button>
</template>

<script>
import { DeleteFilled } from "@vicons/material";
export default {
  inheritAttrs: false,
  components: {
    DeleteFilled,
  },
};
</script>

<style>
</style>