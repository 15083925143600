import Loader from "./Loader.vue";
import { createApp } from "vue";

export default {
  install(app) {
    const instance = createApp(Loader).mount(document.createElement('div'))
    app.config.globalProperties.$loader = {
      show() {
        instance.show();
      },
      hide() {
        instance.hide();
      }
    }
  }
};

